import { FC, useRef } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'

import Toast from '~/src/components/generic/Toast/components/Toast'
import { ToastListProps } from '~/src/components/generic/Toast/types'
import { toastPositionMap } from '~/src/components/generic/Toast/constants'

const ToastList: FC<ToastListProps> = ({
  data,
  delay = 30000,
  position = 'top-right',
  showCloseButton = true,
}) => {
  const toastListRef = useRef<HTMLDivElement>(null)

  if (data.length === 0) return null

  const reversedData = [...data].reverse()

  return (
    <>
      {createPortal(
        <div
          className={twMerge(
            'fixed z-[9999] flex w-full max-w-xs flex-col items-center space-y-4 rounded-lg',
            toastPositionMap[position]
          )}
          aria-live="assertive"
          data-testid="toast-list"
          ref={toastListRef}
        >
          {reversedData.map((toast) => (
            <Toast
              key={toast.id}
              toast={toast}
              delay={toast.delay ?? delay}
              showCloseButton={toast.showCloseButton ?? showCloseButton}
              position={position}
              style={toast.style}
            />
          ))}
        </div>,
        document.body
      )}
    </>
  )
}

export default ToastList
