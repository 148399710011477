import { FC, Fragment, useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Transition } from '@headlessui/react'

import XIcon from '~/src/components/generic/PhosphorIcons/XIcon'
import IconButton from '~/src/components/generic/IconButton'
import { Position, ToastProps } from '~/src/components/generic/Toast/types'
import {
  transitionClasses,
  typeColorMap,
  iconMap,
} from '~/src/components/generic/Toast/constants'
import { useToast } from '~/src/components/generic/Toast/hook/useToast'

import useTimeout from '~/hooks/useTimeout'

type Props = {
  toast: ToastProps
  delay: number
  showCloseButton: boolean
  position: Position
  style?: { container: string; content: string }
}

const Toast: FC<Props> = ({ toast, delay, showCloseButton, position, style }) => {
  const [show, setShow] = useState(true)
  const notify = useToast()

  useTimeout(() => setShow(false), delay)

  const handleClose = useCallback(() => {
    toast.onClose?.()
    notify?.remove(toast.id)
  }, [toast, notify])

  return (
    <Transition
      appear
      show={show}
      as={Fragment}
      afterLeave={handleClose}
      {...transitionClasses[position]}
    >
      <div
        role="alert"
        className={twMerge(
          'relative w-full rounded p-3 shadow-md',
          typeColorMap[toast.type],
          style?.container
        )}
      >
        <div className="flex items-start justify-between space-x-2">
          <div className={twMerge('flex space-x-2', style?.content)}>
            <div>{iconMap[toast.type]}</div>
            <p>{toast.message}</p>
          </div>
          {showCloseButton && (
            <IconButton
              variant="ghost"
              onClick={() => setShow(false)}
              aria-label="Close toast window"
            >
              <XIcon size="20" weight="bold" />
            </IconButton>
          )}
        </div>
      </div>
    </Transition>
  )
}

export default Toast
