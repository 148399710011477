import { forwardRef, PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

import { Variant } from '~/src/components/generic/IconButton/types'
import {
  classVariantsV2,
  disabledClassVariants,
} from '~/src/components/generic/IconButton/constants'
import Spinner from '~/src/components/generic/Spinner'

type Props = {
  variant: Variant
  loading?: boolean
  className?: string
  disabled?: boolean
  onClick?: () => void
}

const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ className, loading, variant, disabled, children, onClick, ...props }, ref) => (
    <button
      ref={ref}
      className={twMerge(
        'flex cursor-pointer items-center justify-center rounded-full',
        classVariantsV2[variant],
        disabled ? disabledClassVariants[variant] : '',
        className
      )}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      <div className={loading ? 'invisible' : 'visible'}>{children}</div>
      <div
        data-testid="spinner"
        className={twMerge(
          'absolute left-0 top-0 h-full w-full items-center justify-center',
          loading ? 'flex' : 'hidden'
        )}
      >
        <Spinner color="#fff" size={32} />
      </div>
    </button>
  )
)

IconButton.displayName = 'IconButton'
export default IconButton
