import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'

import {
  Notification,
  NotificationProps,
} from '~/src/components/shared/Notification/types'

export type NotificationContextType = {
  notifications: Array<Notification>
  update: (messages: Array<Notification>) => void
  isDropdownOpen: boolean
  setIsDropdownOpen: (isOpen: boolean) => void
}

export const NotificationContext = createContext<NotificationContextType | null>(null)

export const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Array<NotificationProps>>([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const update = useCallback((messages: Array<Notification>) => {
    setNotifications(messages)
  }, [])

  const value: NotificationContextType = useMemo(
    () => ({
      update,
      notifications,
      isDropdownOpen,
      setIsDropdownOpen,
    }),
    [update, notifications, isDropdownOpen, setIsDropdownOpen]
  )

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}
