import Mixpanel from 'mixpanel-browser'

import { replacer } from '~/helpers/functions'

export enum MixpanelEvent {
  AppLoad = 'App Load',
  UserLogin = 'User Login',
  UserLogout = 'User Logout',
  SessionStart = 'Session Start',
  SessionEnd = 'Session End',
  ActiveScreenTime = 'Active Screen Time',
  PassiveScreenTime = 'Passive Screen Time',
  HotkeyUsed = 'Hotkey Used',
  CommandKClicked = 'Command-K Clicked',
  RadiusTabView = 'Radius Tab View',
  CostCenterSelected = 'CostCenter Selected',
  FilterClicked = 'Filter Clicked',
  DriverInTableClicked = 'Driver In List Clicked',
  TMWOrderInTableClicked = 'TMW Order In List Clicked',
  StopInTableClicked = 'Stop In List Clicked',
  DriverOnMapClicked = 'Driver On Map Clicked',
  AsyncButtonClicked = 'Async Button Clicked',
  MarkerLabelsToggleClicked = 'Map Marker Labels Toggled Clicked',
  MapExpandContractToggleClicked = 'Map Expand/Contract Toggle Clicked',
  ActionMenuOpened = 'Action Menu Opened',
  ActionMenuItemSelected = 'Action Menu Item Selected',
  DriverVisibilityDutyStatusChanged = 'Driver Visibility Duty Status Changed',
  NotificationCenterOpened = 'Notification Center Opened',
  NotificationSelected = 'Notification Selected',

  /** Load Board */
  LoadBoardSearch = 'Load Board Search',
  LoadBoardFilter = 'Load Board Filter',
  LoadBoardSearchCategorySelected = 'Load Board Search Category Selected',
  LoadBoardPageSelected = 'Load Board Page Selected',
  LoadBoardSortingChanged = 'Load Board Sorting Changed',
  LoadBoardFilterChanged = 'Load Board Filter Changed',
  LoadBoardDetailView = 'Load Board Detail View',
  LoadBoardAssignDriver = 'Load Board Assign Driver',
  LoadBoardUnassignDriver = 'Load Board Unassign Driver',
  LoadBoardDispatch = 'Load Board Dispatch',
  LoadBoardDetailViewLegSelected = 'Load Board Detail View Leg Selected',
  LoadBoardEditStop = 'Load Board Edit Stop',
  LoadBoardDeleteStop = 'Load Board Delete Stop',
  LoadBoardReorderStop = 'Load Board Reorder Stop',
  LoadBoardAddStop = 'Load Board Add Stop',
  LoadBoardDuplicateStop = 'Load Board Duplicate Stop',
  LoadBoardCreateLoad = 'Load Board Create Load',
  LoadBoardCreateLoadDialogOpened = 'Load Board Create Load Dialog Opened',
  LoadBoardEditLegPlannedStartTime = 'Load Board Edit Leg Planned Start Time',
  LoadBoardLegCreated = 'Load Board Leg Created',
  LoadBoardEditBackhaulCode = 'Load Board Edit Backhaul Code',
  LoadBoardAssignTractor = 'Load Board Assign Tractor',
  LoadBoardUnassignTractor = 'Load Board Unassign Tractor',
  LoadBoardSignOffOpenModal = 'Load Board Sign Off Open Modal',
  LoadBoardSignOffComplete = 'Load Board Sign Off Complete',
  LoadBoardCancelLoad = 'Load Board Cancel Load',
  LoadBoardReferenceDeleted = 'Load Board Reference Deleted',
  LoadBoardReferenceEdited = 'Load Board Reference Edited',
  LoadBoardReferenceAdded = 'Load Board Reference Added',

  /**Mobile Dispatch */
  MobileDispatchViewMore = 'Mobile Dispatch View more',

  /** Loadboard/Rydershare Detail View */
  RydershareFilter = 'Customer Visibility Filter',
  DocumentsTabPreview = 'Preview Documents Tab Image/PDF',
  DocumentsTabDownload = 'Download Document From Documents Tab',
  DocumentsTabFilterChanged = 'Document Tab Document Type Filter Changed',
  DocumentsTabSortChanged = 'Document Tab Document Sort Direction Changed',
  TabSelected = 'Loadboard Detail View Tab Selected',
  RydershareMapExpandContractToggleClicked = 'Customer Visibility Map Expand/Contract Toggle Clicked',

  /** CVT */
  SharingButtonClicked = 'Sharing Button Clicked',

  /** User Settings */
  UserSettingsOpen = 'User Settings Open Sidebar',
  UserSettingsFavoriteCostCenterChanged = 'User Settings Favorite Cost Center Changed',
  UserSettingsHomePageChanged = 'User Settings Home Page Changed',

  /** Tracking Page */
  TrackingPageVisited = 'Tracking Page Visited',
}

export const startMixpanelClient = (): void => {
  if (
    process.env.NEXT_PUBLIC_ENV !== 'development' &&
    process.env.NEXT_PUBLIC_MIXPANEL_KEY
  ) {
    Mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? '')
  }
}

export const identifyUser = (userId: string): void => {
  if (process.env.NEXT_PUBLIC_ENV !== 'development') {
    Mixpanel.identify(userId)
  }
}

export const register = (data: { [k: string]: string }): void => {
  if (process.env.NEXT_PUBLIC_ENV !== 'development') {
    Mixpanel.register(data)
    Mixpanel.people.set(data)
  }
}

export const resetMixpanel = (): void => {
  if (process.env.NEXT_PUBLIC_ENV !== 'development') {
    Mixpanel.reset()
  }
}

export const track = (event: MixpanelEvent, extraData = {}): void => {
  if (process.env.NEXT_PUBLIC_ENV !== 'development') {
    Mixpanel.track(event, {
      ...extraData,
      tmsVersion: process.env.VERSION,
      eventCreatedAt: new Date().toISOString(),
    })
  } else {
    // eslint-disable-next-line no-console
    console.log(
      `Mixpanel track: ${event}, data: ${JSON.stringify(extraData, replacer)}`
    )
  }
}

export const timeEvent = (event: MixpanelEvent): void => {
  if (process.env.NEXT_PUBLIC_ENV !== 'development') {
    Mixpanel.time_event(event)
  } else {
    console.log(`Mixpanel time_event: ${event}`) // eslint-disable-line no-console
  }
}
