import { NextPageContext } from 'next'
import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import * as Sentry from '@sentry/nextjs'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

import type { NormalizedCacheObject } from '@apollo/client'

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
  const errors = []

  if (networkError && 'statusCode' in networkError && networkError.statusCode == 401) {
    window.location.assign(`/login?redirect=${window.location.pathname}`)
    errors.push(
      `[Network error]: ${networkError.toString()}, Code: ${
        'statusCode' in networkError ? networkError.statusCode : ''
      }`
    )
  }

  if (graphQLErrors) {
    errors.push(
      ...graphQLErrors.map(
        ({ message, locations, path }) =>
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }

  if (errors.length) {
    const requestId = operation.getContext().headers['X-BATON-REQUEST-ID']
    const errorMessage = `[Request ${requestId}]: ${errors.join(', ')}`

    if (process.env.NEXT_PUBLIC_ENV === 'development') {
      throw new Error(errorMessage)
    }
  }
})

const requestIdLink = setContext(() => {
  const requestId = `${Date.now().toString(16)}${Math.random().toString(16).slice(2)}`

  Sentry.setTag('request_id', requestId)

  return {
    headers: {
      'X-BATON-REQUEST-ID': requestId,
    },
  }
})

const fileHttpLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_SERVER_URL, // Server URL (must be absolute)
  credentials: 'include',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'client-name': 'TMS Front End',
    'client-version': process.env.VERSION as string,
  },
  fetch,
}) as unknown as ApolloLink

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.NEXT_PUBLIC_WS_SERVER_URL}`,
  })
)

const sentryPerformanceMonitoringLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server
  const transaction = Sentry.startTransaction({
    op: 'gql',
    name: operation.operationName,
  })
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction))

  const span = transaction.startChild({
    op: 'request',
    data: {
      source: JSON.stringify(operation.query.loc?.source),
      variables: JSON.stringify(operation.variables),
    },
  })

  JSON.stringify(operation.query.loc?.source)

  return forward(operation).map((data) => {
    // Called after server responds
    span.finish()
    transaction.finish()
    return data
  })
})

const createApolloClient = (
  initialState: NormalizedCacheObject,
  ctx: NextPageContext
): ApolloClient<NormalizedCacheObject> => {
  const links =
    typeof window != 'undefined' && wsLink
      ? [
          requestIdLink,
          errorLink,
          sentryPerformanceMonitoringLink,
          split(
            ({ query }) => {
              const definition = getMainDefinition(query)
              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              )
            },
            wsLink,
            fileHttpLink
          ),
        ]
      : [sentryPerformanceMonitoringLink, fileHttpLink]

  const cache = new InMemoryCache().restore(initialState)

  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from(links),
    cache: cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

export default createApolloClient
