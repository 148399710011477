import { FC } from 'react'
import { Spinner } from '@phosphor-icons/react'

import { IconPropsType } from '~/src/components/generic/PhosphorIcons/types'

const Icon: FC<IconPropsType> = ({ color, size, weight, mirrored }) => (
  <Spinner color={color} size={size} weight={weight} mirrored={mirrored} />
)

export default Icon
