const colors = {
  gray: {
    25: '#F5F6F6',
    50: '#ECEDED',
    75: '#E3E4E5',
    100: '#C5C6C7',
    200: '#A9AAAC',
    300: '#828386',
    400: '#696B6F',
    500: '#44464B',
    600: '#3E4044',
    700: '#303236',
    800: '#252729',
    900: '#1D1D20',
  },
  red: {
    50: '#FEEEEB',
    100: '#FACAC0',
    200: '#F8B1A2',
    300: '#F58D78',
    400: '#F3775D',
    500: '#F05535',
    600: '#DA4D30',
    700: '#AA3C26',
    800: '#842F1D',
    900: '#652416',
  },
  green: {
    50: '#E6F9F2',
    100: '#B2EED7',
    200: '#8CE5C3',
    300: '#58D9A8',
    400: '#38D297',
    500: '#06C77D',
    600: '#05B572',
    700: '#048D59',
    800: '#036D45',
    900: '#035435',
  },
  blue: {
    50: '#ecf7fd',
    100: '#e3f3fc',
    200: '#c5e6f8',
    300: '#44ade8',
    400: '#3d9cd1',
    500: '#368aba',
    600: '#307ca7',
    700: '#29688b',
    800: '#1f4e68',
    900: '#183d51',
  },
  yellow: {
    50: '#FEF9E6',
    100: '#FAEBB2',
    200: '#F8E28C',
    300: '#F5D558',
    400: '#F3CD37',
    500: '#F0C005',
    600: '#DAAF05',
    700: '#AA8804',
    800: '#846A03',
    900: '#655102',
  },
  brightPurple: {
    50: '#F3E6F6',
    100: '#DAB0FB',
    200: '#C88AF9',
    300: '#AF54F7',
    400: '#A033F5',
    500: '#8800F3',
    600: '#7C00DD',
    700: '#6100AD',
    800: '#4B0086',
    900: '#390066',
  },
  brightPink: {
    50: '#FFE6F8',
    100: '#FFB0E9',
    200: '#FF8ADE',
    300: '#FE54CF',
    400: '#FE33C5',
    500: '#FE00B7',
    600: '#E700A7',
    700: '#B40082',
    800: '#8C0065',
    900: '#6B004D',
  },
  cyan: {
    50: '#E6F8FB',
    100: '#B2E8F2',
    200: '#8CDDEB',
    300: '#58CEE2',
    400: '#38C5DD',
    500: '#06B6D4',
    600: '#05A6C1',
    700: '#048197',
    800: '#036475',
    900: '#034C59',
  },
  darkPink: {
    50: '#F9E6FC',
    100: '#EDB0F4',
    200: '#E48AEF',
    300: '#D754E8',
    400: '#D033E3',
    500: '#C400DC',
    600: '#B200C8',
    700: '#8B009C',
    800: '#6C0079',
    900: '#52005C',
  },
  lightBlue: {
    50: '#E7F6FD',
    100: '#B4E3F8',
    200: '#90D6F5',
    300: '#5EC3F0',
    400: '#3EB7ED',
    500: '#0EA5E9',
    600: '#0D96D4',
    700: '#0A75A5',
    800: '#085B80',
    900: '#064562',
  },
  lime: {
    50: '#FAFCEB',
    100: '#F0F4C2',
    200: '#E8EFA4',
    300: '#DEE87A',
    400: '#D7E361',
    500: '#CDDC39',
    600: '#BBC834',
    700: '#929C28',
    800: '#71791F',
    900: '#565C18',
  },
  mediumPurple: {
    50: '#F5E6EE',
    100: '#E1B0CB',
    200: '#D38AB1',
    300: '#BF548E',
    400: '#B23378',
    500: '#9F0056',
    600: '#91004E',
    700: '#71003D',
    800: '#57002F',
    900: '#430024',
  },
  darkGreen: {
    50: '#E6F0EE',
    100: '#B2D2C9',
    200: '#8CBCAF',
    300: '#589D8A',
    400: '#388A73',
    500: '#066D50',
    600: '#056349',
    700: '#044D39',
    800: '#033C2C',
    900: '#032E22',
  },
  darkBlue: {
    50: '#E7F0F5',
    100: '#B3CFDE',
    200: '#8FB8CF',
    300: '#5C98B9',
    400: '#3C84AB',
    500: '#0B6596',
    600: '#0A5C89',
    700: '#08486B',
    800: '#063853',
    900: '#052A3F',
  },
  orange: {
    50: '#FFF5E6',
    100: '#FFDFB0',
    200: '#FFD08A',
    300: '#FFBA54',
    400: '#FFAD33',
    500: '#FF9800',
    600: '#E88A00',
    700: '#B56C00',
    800: '#8C5400',
    900: '#6B4000',
  },
  darkPurple: {
    50: '#EDEBF8',
    100: '#C8C2E9',
    200: '#ADA5DE',
    300: '#877CCF',
    400: '#7062C5',
    500: '#4C3BB7',
    600: '#4536A7',
    700: '#362A82',
    800: '#2A2065',
    900: '#20194D',
  },
  teal: {
    50: '#EDFCF8',
    100: '#C7F6E8',
    200: '#ABF1DD',
    300: '#85EBCE',
    400: '#6DE7C5',
    500: '#49E1B6',
    600: '#42CDA6',
    700: '#34A081',
    800: '#287C64',
    900: '#1F5F4C',
  },
  bloodOrange: {
    50: '#FFEEE9',
    100: '#FFCBBA',
    200: '#FFB299',
    300: '#FF8E6B',
    400: '#FF794E',
    500: '#FF5722',
    600: '#E84F1F',
    700: '#B53E18',
    800: '#8C3013',
    900: '#6B250E',
  },
  green: {
    50: '#EDF7EE',
    100: '#C8E6C9',
    200: '#ADDAAF',
    300: '#87C98A',
    400: '#70BF73',
    500: '#4CAF50',
    600: '#459F49',
    700: '#367C39',
    800: '#2A602C',
    900: '#204A22',
  },
  map: {
    brightPurple: '#A033F5',
    brightBlue: '#4D6CE5',
    brightPink: '#FE33C5',
    mediumPurple: '#B23378',
    darkPink: '#F033E3',
    cyan: '#38C5DD',
    lightBlue: '#3EB7ED',
    darkGreen: '#388A73',
    darkBlue: '#3C84AB',
    darkPurple: '#7062C5',
    teal: '#6DE7C5',
    lime: '#D7E361',
    orange: '#FFAD33',
    bloodOrange: '#FF794E',
    green: '#70BF73',
  },
  ryder: {
    'red-primary': '#CE1126',
    'red-secondary': '#CD2028',
  },
}

module.exports = colors
