'use client'

import React, { FC, ReactNode, useEffect } from 'react'
import { Wrapper } from '@googlemaps/react-wrapper'
import * as Sentry from '@sentry/nextjs'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import { ToastContextProvider } from '~/src/components/generic/Toast/context'

import { NotificationContextProvider } from '~/src/components/shared/Notification/context'

import { MixpanelEvent, startMixpanelClient, track } from '~/clients/mixpanelClient'
import withApollo from '~/hoc/withApollo'

import '~/src/styles/globals.css'

const MyApp: FC<{ children: ReactNode }> = ({ children }) => {
  useEffect(() => {
    startMixpanelClient()
    track(MixpanelEvent.AppLoad)
  }, [])

  return (
    <Sentry.ErrorBoundary>
      <ToastContextProvider>
        <NotificationContextProvider>
          <Wrapper
            apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''}
            libraries={['marker']}
          >
            {children}
          </Wrapper>
        </NotificationContextProvider>
      </ToastContextProvider>
    </Sentry.ErrorBoundary>
  )
}

const LDApp = withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID ?? '',
  context: {
    kind: 'user',
    anonymous: true,
  },
})(MyApp as React.ComponentType)

export default withApollo()(LDApp)
