import { CSSProperties, FC } from 'react'
import { twMerge } from 'tailwind-merge'

import SpinnerIcon from '~/src/components/generic/PhosphorIcons/SpinnerIcon'

import colors from '~/config/colorsScheme'

type Props = {
  color?: string
  size?: number
  className?: string
  style?: CSSProperties
}

const Spinner: FC<Props> = ({ color, size, className, style }) => (
  <span className={twMerge('animate-spin duration-1000', className)} style={style}>
    <SpinnerIcon size={size ?? 40} color={color ?? colors.blue[500]} />
  </span>
)

export default Spinner
