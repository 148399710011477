import { ReactNode } from 'react'

import { Type, Position } from '~/src/components/generic/Toast/types'
import WarningCircleIcon from '~/src/components/generic/PhosphorIcons/WarningCircleIcon'
import CheckCircleIcon from '~/src/components/generic/PhosphorIcons/CheckCircleIcon'
import WarningIcon from '~/src/components/generic/PhosphorIcons/WarningIcon'

import colors from '~/config/colorsScheme'

export const typeColorMap: { [key in Type]: string } = {
  success: 'bg-green-50 text-green-700 border-green-600 border-2',
  error: 'bg-red-50 text-red-600 border-red-600 border-2',
  warning: '',
}

export const transitionClassesRight = {
  enter: 'transition transform ease-in-out duration-500 sm:duration-700',
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'transition transform ease-in-out duration-500 sm:duration-700',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-full',
}

export const transitionClassesLeft = {
  enter: 'transition transform ease-in-out duration-500 sm:duration-700',
  enterFrom: '-translate-x-full',
  enterTo: 'translate-x-0',
  leave: 'transition transform ease-in-out duration-500 sm:duration-700',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-[-100%]',
}

export const toastPositionMap: { [key in Position]: string } = {
  'top-right': 'top-5 right-5',
  'top-left': 'top-5 left-5',
  'bottom-right': 'bottom-5 right-5',
  'bottom-left': 'bottom-5 left-5',
}

export const transitionClasses: {
  [key in Position]: {
    enter: string
    enterFrom: string
    enterTo: string
    leave: string
    leaveFrom: string
    leaveTo: string
  }
} = {
  'top-right': transitionClassesRight,
  'top-left': transitionClassesLeft,
  'bottom-right': transitionClassesRight,
  'bottom-left': transitionClassesLeft,
}

export const iconMap: { [key in Type]: ReactNode } = {
  error: <WarningCircleIcon size="24" color={colors.red[600]} />,
  success: <CheckCircleIcon size="24" color={colors.green[700]} />,
  warning: <WarningIcon size="24" color={colors.yellow[600]} />,
}
