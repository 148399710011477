import { Variant } from '~/src/components/generic/IconButton/types'

export const classVariants: { [key in Variant]: string } = {
  primary: 'bg-blue-500 hover:bg-blue-400',
  secondary: 'border border-gray-75 bg-gray-50 hover:bg-gray-100 rounded',
  outlined:
    'bg-white hover:bg-gray-25 border border-blue-600 shadow hover:shadow-md rounded active:border-blue-200 h-8 w-8 text-blue-600',
  ghost:
    'bg-transparent hover:bg-gray-50 rounded active:ring-gray-75 active:ring-1 p-0.5',
}

export const classVariantsV2: { [key in Variant]: string } = {
  primary:
    'shadow hover:shadow-md bg-gray-500 border border-gray-500 text-white hover:bg-gray-900 hover:border-gray-900 active:border-gray-100 disabled:text-gray-25 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-none rounded size-8',
  secondary: 'border border-gray-75 bg-gray-50 hover:bg-gray-100 rounded',
  outlined:
    'bg-white hover:bg-gray-25 border border-gray-500 hover:border-gray-900 shadow hover:shadow-md rounded active:border-gray-100 h-8 w-8 text-gray-900 shadow',
  ghost:
    'bg-transparent hover:bg-gray-50 rounded active:ring-gray-75 active:ring-1 p-0.5',
}

export const disabledClassVariants: { [key in Variant]: string } = {
  primary: 'bg-gray-300 pointer-events-none',
  secondary: 'border border-gray-75 bg-gray-50 pointer-events-none text-gray-200',
  outlined:
    'bg-transparent border-gray-75 shadow-none pointer-events-none text-gray-100',
  ghost: 'bg-transparent pointer-events-none text-gray-300',
}
