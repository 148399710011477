import { createContext, ReactNode, useCallback, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import ToastList from '~/src/components/generic/Toast'
import {
  ToastId,
  ToastOptions,
  ToastProps,
  Type,
  Position,
} from '~/src/components/generic/Toast/types'

type ToastContextType = {
  success: (message: ReactNode, option: ToastOptions) => void
  error: (message: ReactNode, option: ToastOptions) => void
  warning: (message: ReactNode, option: ToastOptions) => void
  remove: (id: ToastId) => void
}

const defaultToastContext: ToastContextType = {
  success: () => undefined,
  error: () => undefined,
  warning: () => undefined,
  remove: () => undefined,
}

export const ToastContext = createContext<ToastContextType>(defaultToastContext)

/**
 * ToasContextProvider is a wrapper component that provides the toast context to its children
 * @param children - Children to be wrapped
 * @returns
 * @example
 * ```tsx
 * <ToastContextProvider>
 *  <App />
 * </ToastContextProvider>
 * ```
 *
 * @example
 * ```tsx
 * const toast = useToast()
 *
 * <button onClick={() => toast.success('Success', { delay: 1000, id: '1' })}>
 * Success
 * </button>
 * <button onClick={() => toast.error('Error', { delay: 1000, id: '1' })}>
 * Error
 * </button>
 * ```
 */
export const ToastContextProvider = ({
  children,
  position,
}: {
  children: ReactNode
  position?: Position
}) => {
  const [toasts, setToasts] = useState<Array<ToastProps>>([])

  const addToast = useCallback(
    (type: Type, message: ReactNode, option: ToastOptions) => {
      const toastId = option.id ?? uuidv4()

      setToasts((prev) => [...prev, { message, type, ...option, id: toastId }])
    },
    []
  )

  /**
   * @param message - Message to be displayed in the toast
   * @param option - Options for the toast
   * @param option.id - Unique id for the toast, if not provided a random id will be generated
   * @param option.delay - Delay in ms after which the toast will be removed
   * @param option.onClose - Callback to be called when the toast is closed
   */
  const success = useCallback(
    (message: ReactNode, option: ToastOptions) => {
      addToast('success', message, option)
    },
    [addToast]
  )

  const error = useCallback(
    (message: ReactNode, option: ToastOptions) => {
      addToast('error', message, option)
    },
    [addToast]
  )

  const warning = useCallback(
    (message: ReactNode, option: ToastOptions) => {
      addToast('warning', message, option)
    },
    [addToast]
  )

  const remove = useCallback((id: ToastId) => {
    setToasts((prevState) => prevState.filter((toast) => toast.id !== id))
  }, [])

  const value: ToastContextType = useMemo(
    () => ({
      success,
      error,
      remove,
      warning,
    }),
    [success, error, remove, warning]
  )

  return (
    <ToastContext.Provider value={value}>
      <ToastList data={toasts} position={position} />
      {children}
    </ToastContext.Provider>
  )
}
